@use "sass:math";

.waterfall-v1_0 {
  @import "../shared/_defaults";
  @import "../lib/animate";
  @import "modal";
  @include display-header;
  @include origin-flag-tagboard-classic;

  .posts {
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 16vmin);
    overflow: hidden;

    .post {
      position: relative;
      width: 100%;
      height: auto;
      background-color: $white;
      box-shadow: inset 0 0 0 1px rgba($black, .1);
      color: $dark-gray;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .media-module {
      position: relative;
      width: 100%;
      height: auto;
    }

    .media {
      display: block;
      width: 100%;
      height: auto;
    }

    .text-module {
      position: relative;
      width: 100%;
      height: auto;
      padding: .5em;
    }

    .text .text-module, .chat .text-module{
      padding-bottom: 1em;
    }

    .meta-author {
      position: relative;
      font-size: .8em;
      padding-left: 2.75em;
      padding-right: 1em;
      margin-bottom: .5em;
    }

    .no-avatar {
      .meta-author {
        padding-left: 0;
      }
      .avatar {
        display: none;
      }
    }

    .avatar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      margin: auto;
      width: 2.25em;
      height: 2.25em;
      border-radius: 100%;
      box-shadow: 0 0 4px rgba($black, .5);
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .user-img {
      visibility: hidden;
      width: 100%;
      height: 100%;
    }

    .author,
    .handle {
      @include ellipsis;
      float: left;
      clear: both;
      max-width: 100%;
      line-height: 2.25;
    }

    .twitter,
    .tiktok {
      .author,
      .handle {
        line-height: 1.3;
      }

      .handle {
        font-size: .8em;
      }
    }

    .reddit .subreddit {
      float: left;
      clear: both;
      max-width: 100%;
      line-height: 1.3;
      @include ellipsis;

      + .handle {
        font-size: .8em;
        line-height: 1.3;
      }
    }
    
    .twitch.has-media,
    .heymarket.has-media {
      .text-module {
        display: none;
      }
    }

    .post-time {
      display: none;
      font-size: .8em;
      margin-bottom: .5em;
      @include ellipsis;
    }

    .timestamp {
      font-size: .8em;
    }

    .post-title {
      margin-bottom: .5em;
      font-weight: bold;
    }

    .origin-flag {
      top: .5em;
      right: -.3em;
    }
  }

  .interactive {
    .posts {
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  // POST MODAL

  .post-modal {
    .post {
      @include simple-center-align;
      width: 80%;
      height: 80%;
    }
  }

  // CUSTOMIZATIONS

  .gutter-none {
    .posts {
      .post {
        padding: .25em;
      }

      .origin-flag {
        right: 0;
        border-top-right-radius: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  .gutter-small {
    .posts,
    .post-container {
      padding: .3em;
    }

    .posts {
      height: calc((100% + .6em) - 16vmin);
    }
  }

  .gutter-medium {
    .posts,
    .post-container {
      padding: .6em;
    }

    .posts {
      height: calc((100% + 1.2em) - 16vmin);
    }
  }

  .gutter-large {
    .posts,
    .post-container {
      padding: 1.2em;
    }

    .posts {
      height: calc((100% + 2.4em) - 16vmin);
    }
  }

  .hide-media-captions .posts {
    .has-media {
      color: $white;
      background-color: $black;

      .text-module {
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, rgba($black, .8), rgba(black, 0));
      }

      .meta-author {
        margin-bottom: 0;
      }
    }
  }

  .share-it.no-text {
    .media-module {
      float: none;
      margin: 0 auto;
    }

    .blurred-media-module {
      width: 100%;
      display: block;
    }
  }

  // dark post style

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .posts .post,
    .post-modal .text-module {
      background-color: rgba($black, .5);
    }
  }

  // LAYOUT

  .post-container {
    position: relative;
    width: 100%;
    height: auto;

    &--qr-code {
      height: 0;
      padding-top: 100% !important;
      transition: none !important;
    }
  }

  .waterfall-column {
    float: left;
  }

  @for $i from 1 through 8 {
    .waterfall-columns-#{$i} {
      .waterfall-column {
        width: math.div(100%, $i);
      }
    }
  }

  //ANIMATIONS
  .waterfall-anim-enter {
    transform: translateY(-100%);
  }

  .waterfall-anim-leave-to {
    display:none;
  }

  .waterfall-column {
    .post-container {
      transition: all 700ms ease;
    }

    .Fade.waterfall-anim-enter {
      opacity: 0;
      transform: translateY(0);
    }

    .Fade.waterfall-anim-enter-to {
      opacity: 1;
      transition-delay: 500ms;
      transition-duration: 300ms;
    }

    .Slide:not(.Down).waterfall-anim-enter {
      transform: translate3d(-50%,0, 0);
      opacity: 0;
    }

    .Slide:not(.Down).waterfall-anim-enter-to {
      transition-delay: 700ms;
      transition-duration: 300ms;
      opacity: 1;
      transform: translate3d(0,0, 0);
    }

    .Parallax.waterfall-anim-enter {
      transform: translate3d(0,-60px, 0);
    }

    .Parallax.waterfall-anim-enter-to {
      transition-duration: 800ms;
      transform: translate3d(0,0, 0);
    }

    .Scale.waterfall-anim-enter {
      transform: scale(0);
      -webkit-transform: scale(0);
      transform-origin: top left;
    }

    .Scale.waterfall-anim-enter-to {
      transform-origin: top left;
    }

    .Zoom.waterfall-anim-enter {
      opacity: 0;
      transform: scale(0.5);
      transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }

    .Zoom.waterfall-anim-enter-to {
      transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }
  }

  // QR CODE

  .post-container .qr-module {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .square-placeholder {
    width: auto;
    height: 100%;
    display: none;
  }

}
